@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        p {
            font-size: 0.875rem;

        }

        .reciteWrapper {
            position: fixed;
            
            .recite-btn {
                border: none;
                background: transparent;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }
        }

        .navigations {
            a {
                font-size: 0.875rem;
                font-weight: 500;
            }
        }

        .sourceflow {
            svg {
                width: 120px;
                height: auto;

                path {
                    fill: $base-black;
                }
            }
        }

        .impellem-group {
            white-space: nowrap;

            svg {
                width: 150px;
                height: auto;
            }
        }

        a {
            @include trans;

            &:hover,
            &:focus,
            &:active {
                color: $font-color;
                opacity: 0.5;
            }
        }
    }
}