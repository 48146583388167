@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        background: $primary-color;
        color: $base-white;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 9;
        @include trans;

        .reciteWrapper {
            .recite-btn {
                border: none;
                background: transparent;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }
        }

        .mainWrapper {
            padding: 0.325rem 0;
        }

        .lang_select {
            .btn-secondary {
                background: transparent;
                border: none;
                font-weight: 500;
            }

            .dropdown-menu {
                border: none;
                min-width: auto;
                padding: 5px 15px;
                width: auto;
                box-shadow: 1px 1px 15px rgba($base-black, 0.2);
                text-align: center;

                .dropdown-item {
                    font-weight: 500;
                    display: inline-block;

                    &.active {
                        display: none;
                    }
                }

                &:before {
                    content: "";
                    position: absolute;
                    right: 20px;
                    top: -10px;
                    width: 0;
                    height: 0;
                    border-color: transparent transparent #fff;
                    border-style: solid;
                    border-width: 0 9px 10px;
                }
            }

            .dropdown-item {
                padding: 0;
                color: $base-black;
                min-width: 20px;
                min-height: 20px;
                @include trans;

                &.active {
                    background: transparent;
                }

                &:hover,
                &:focus,
                &:active {
                    opacity: 0.5;
                }
            }

            span {
                display: none;

                &.active {
                    display: block;
                }
            }

            svg {
                border: 1px solid $base-white;
                width: 25px;
                height: 100%;
                @include border-radius(100%);
            }
        }

        .nav-item {
            a {
                @include trans;

                &:hover,
                &:focus,
                &:active {
                    color: $primary-color;
                }
            }

            .no-dropdown {
                color: $base-white !important;

                &:hover,
                &:focus,
                &:active {
                    color: $yellow-color !important;
                }
            }
        }

        .mobile-menu {
            // width: 100px;
            // height: 40px;
            padding: 0.7rem 1rem;
            background: $bg-light;
            border: none;
            @include trans;
            @include border-radius(50px);

            &.active {
                &:before {
                    top: 20px;
                    @include transform(rotate(45deg));
                }

                &:after {
                    top: 20px;
                    @include transform(rotate(-45deg));
                }
            }

            // &:before {
            //     top: 16px;
            //     left: 8px;
            // }

            // &:after {
            //     top: 23px;
            //     left: 8px;
            // }

            // &:before,
            // &::after {
            //     content: "";
            //     position: absolute;
            //     width: 25px;
            //     height: 2px;
            //     background: $bg-dark;
            // }

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;

                &:before,
                &::after {
                    background: $base-white;
                }
            }
        }

        .mobile-nav {
            .nav-item {
                > a {
                    display: inline-block;
                    font-size: 1.2rem;
                    font-weight: 500;
                    padding: 0.5rem 1.2rem;
                    color: $base-white;
                }
            }
        }

        .logo {
            cursor: pointer;
            @include trans;

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }
        }

        button {
            &.dropdown {
                color: $base-white;
                @include trans;

                &.has-children {
                    padding-right: 1.5rem;

                    &:after {
                        content: "";
                        background-image: url("/site-assets/svg/white-caret-arrow.svg");
                        background-repeat: no-repeat;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        right: 0;
                        top: 7px;
                        @include transform(rotate(90deg));
                    }
                }

                &:hover,
                &:focus,
                &:active {
                    color: $yellow-color;
                }
            }
        }

        .dropdown-navigations {
            background: rgba($base-white, 0.9);
            left: 0;
            right: 0;
            color: $font-color;
            @include border-radius(25px);
            // @mixin box-shadow($top, $left, $blur, $size, $color) {
            @include box-shadow(1px, 1px, 5px, 1px, rgba(0, 0, 0, 0.1));

            a {
                @include trans;

                &:hover,
                &:focus,
                &:active {
                    color: $primary-color;
                }
            }
        }

        .search-icon {
            cursor: pointer;
            padding: 1rem;
            border: 1px solid $primary-color;
            background: $light-primary-color;
            @include border-radius(100%);
            @include trans;

            svg {

                width: 20px;
                height: 20px;

                path {
                    fill: $base-white;
                }
            }

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }
        }

        .telephone-icon {
            svg {
                width: 18px;
                height: 18px;
            }
        }

        .mobile-search {
            .primaryBtn {
                padding: 0.7rem;
            }

            .icon {
                right: 15px;
                top: 7px;

                svg {
                    path {
                        fill: $secondary-color;
                    }
                }
            }

            .btn {
                width: 100%;
                background: $base-white;
                color: $font-color;
                text-align: left;

                &:after {
                    content: "";
                    position: absolute;
                    right: 18px;
                    top: 11px;
                    background-image: url("/site-assets/svg/black-magnifying-glass.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 18px;
                    height: 18px;
                }
            }

            svg {

                width: 20px;
                height: 20px;

                path {
                    fill: $base-white;
                }
            }

        }

        input {
            &#search {
                text-align: left;
                width: 100%;
                outline: none;
                padding: 0.5rem 4rem 0.5rem 2rem;
                background: $base-white;
                border: none;
                color: $font-color;
                @include border-radius(25px);


            }
        }

        .search-results {
            overflow-y: auto;
            color: $base-white;

            ul {
                padding-left: 1rem;
            }

            a {
                @include trans;

                &:hover,
                &:focus,
                &:active {
                    color: $primary-color;
                }
            }
        }

        @media (min-width: $breakpoint-lg) {
            .search-results {
                height: 100vh;
                padding-bottom: 10rem;
            }
        }

        @media (max-width: $breakpoint-lg) {

            // .logo {
            //     svg {
            //         max-width: 90px;
            //     }
            // }
            .secondaryBtn {
                padding: 0.7rem 1rem
            }
        }
    }

    &:global.main-navigation.active {
        border-bottom: 1px solid $base-white;
    }

    &:global.mobile-dropdown {
        position: fixed;
        background: $secondary-color;
        z-index: 8;
        height: 100%;
        width: 100%;
        top: -200%;
        @include trans;
        padding-top: 9rem;
        color: $font-color;

        &.active {
            top: 0;
            overflow-y: scroll;

        }

        .accordion-item {
            border: none;
            background: transparent;

            &:last-of-type {
                .accordion-collapse {
                    border-radius: 0;
                }
            }

            .accordion-button {
                background: transparent;
                box-shadow: none;
                font-weight: 500;
                font-size: 1.2rem;
                padding-right: 3rem;
                color: $base-white;

                &:after {
                    content: "";
                    background-image: url("/site-assets/svg/black-arrow.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                    filter: brightness(0) invert(1);
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    right: 25px;
                    top: 17px;
                    @include transform(rotate(-90deg));
                }

                &.collapsed {
                    &:after {
                        @include transform(rotate(90deg));
                    }
                }

            }
        }

        .accordion-body {
            background: $base-white;

            p {
                &:last-child {
                    margin: 0;
                }
            }

            a {
                font-weight: 500;
                padding: 0 !important;

                &:hover,
                &:focus,
                &:active {
                    color: $font-color;
                    opacity: 0.5;
                }
            }
        }

        .mobile-language {

            .btn-secondary {
                color: $font-color;
                padding: 0;
            }

            hr {
                border-color: $base-white;
            }

            .lang_select {
                .dropdown-menu {
                    max-width: 80px;

                    &:before {
                        content: none;
                    }
                }
            }
        }

        .mobileWrapper {
            max-height: 75vh;
        }
    }

    &:global.site-search {
        background: $dark-gray;
        padding: 1.2rem;

        .container {
            max-width: 1440px;
        }

        .primaryBtn {
            max-width: 50px;
        }

        .btn {
            text-align: left;
            width: 100%;
            outline: none;
            padding: 0.5rem 4rem 0.5rem 2rem;
            background: $base-white;
            border: none;
            color: $font-color;
            @include border-radius(25px);

            &:after {
                content: "";
                position: absolute;
                right: 18px;
                top: 11px;
                background-image: url("/site-assets/svg/black-magnifying-glass.svg");
                background-repeat: no-repeat;
                background-size: contain;
                width: 18px;
                height: 18px;
            }
        }

        .search {
            .search-icon {
                width: 20px;
                height: 20px;
                right: 20px;
                top: 5px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        button {
            padding: 0.9rem;

            svg {
                min-width: 20px;
                min-height: 20px;

                path {
                    fill: $base-white;
                }
            }
        }

    }
}